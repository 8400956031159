<script setup>
import { useRouter } from "vue-router/composables";
import { onMounted, onUnmounted, ref } from "vue";
import {
  setSelectedApp,
  setXentralConfig
} from "@/composables/useSalesChannelManagementApp";
import Auth from "@/components/General/Auth/auth";
import { Error } from "@/core/plugins/swal";
import {
  addEventToLoadingQueue,
  removeEventFromLoadingQueue
} from "@/composables/useLoadingQueue";
import Progressbar from "@/components/Tools/Progressbar.vue";
import Loader from "@/components/Tools/Loader.vue";

const router = useRouter();
const error = ref(false);

onMounted(() => {
  window.addEventListener("message", handleConfig);

  getConfig();
});

onUnmounted(() => {
  window.removeEventListener("message", handleConfig);
});

function getConfig() {
  error.value = false;
  window.top?.postMessage({ action: "getConfig" }, "*");
}

async function handleConfig(event) {
  if (event.data.action === "getConfig") {
    addEventToLoadingQueue({
      key: "appLogin"
    });

    let xentralData = event.data;
    let idToken = xentralData?.data?.idToken;
    xentralData.data.idToken = parseJwt(idToken);
    await setXentralConfig(xentralData);

    let authData = {
      idToken: idToken,
      baseUrl: event.data?.data?.baseUrl
    };

    let appName = event.data?.data?.id.replace("connect-", "");
    await setSelectedApp(appName);

    Auth.appLogin(authData, idToken).then(response => {
      if (response.success) {
        removeEventFromLoadingQueue({
          key: "appLogin"
        });
        router.push({
          name: "salesChannelManagementApp",
          params: { name: appName }
        });
      } else {
        removeEventFromLoadingQueue({
          key: "appLogin"
        });
        error.value = true;
        Error(response.data.message);
      }
    });
  }
}

function parseJwt(token) {
  let base64Url = token.split(".")[1];
  let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  let jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
  return JSON.parse(jsonPayload);
}
</script>

<template>
  <div>
    <Progressbar />

    <div class="apps-auth-panel">
      <Loader v-if="!error" />

      <button v-else class="btn btn-primary" @click="getConfig">
        {{ $t("salesChannelManagementApp.authRetry") }}
      </button>
    </div>
  </div>
</template>

<style scoped lang="scss">
.apps-auth-panel {
  height: 100%;
  width: 100%;
  display: grid;
  place-content: center;
  background-color: $color-connect-background;

  .btn {
    font-size: 2.5rem;
    padding: 20px 60px;
    width: 450px;

    i {
      padding: 0;
      font-size: 2.5rem;
    }
  }
}
</style>
